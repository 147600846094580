import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Home from '../containers/whywhy';
import TopMenu from '../components/topMenu';

const IndexPage = () => {
  return (
    <Layout>
                                <TopMenu></TopMenu>

      <SEO title="Vivo Vibe" />
      <Home />
    </Layout>
  );
};

export default IndexPage;
