import React from 'react';
import PropTypes from 'prop-types';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
// import Img from 'gatsby-image';
// import { useStaticQuery, graphql } from 'gatsby';
import VivoVideo from '../assets/videos/vv-v1-2.mp4';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Logo from '../components/logo';
import LogoDisplay from '../components/logoDisplay';
import TopMenu from '../components/topMenu';
import ImgLogoDisplay from '../components/imgLogoDisplay';



const Home = ({ className }) => {
  const data = useStaticQuery(graphql`
    query whyNeeds {
      logoLogo: file(relativePath: { eq: "vv.png" }) {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
          whitelogo: file(relativePath: { eq: "vv-white.png" }) {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
    
    
    
    return (

      <section className="h-screen w-screen">

    <div className="w-screen h-screen fixed">
          <video
            autoPlay
            muted
            playsInline
            loop
            className="object-cover h-full w-full fixed"
          >
            <source src={VivoVideo} type="video/mp4" />
          </video>
    <div className="w-full h-full bg-black opacity-50 fixed">
       
        
        </div>

        </div>
       


        <div className="relative flex h-screen w-screen flex-col pt-40">
        <h1 class="text-center pt-10 text-white ext-3xl tracking-wide md:text-4xl vivo">Why Network Marketing?</h1>
        <div class="flex justify-center">
        <div class="w-4/5 p-5">
        <ul class="text-white">
        <li>Network marketing is a $220 billion well established industry with well-known methodologies for growth.</li>
        <li>It allows people to earn at their own pace. People with children at home can earn additional income on the side, couples can make it a family business, and singles can jet set across the globe in this industry.</li>
        <li>Husbands and wives, close friends and lost family are pulled together in this business and their relationships can thrive.</li>
        <li>It provides an avenue for the lonely to interact.</li>
        <li>It can be lucrative. Depending on your goals, network marketing can be a 7 figure income.</li>
        <li>You’re doing business with people you have a personal relationship with. Support is 1 friendship away.</li>
        <li>Most network marketing company’s focus on improving your life by incorporating travel as their incentives.</li>
        <li>You get to control your destiny and grow at the speed of your will.</li>
        <li>The model for business growth is most successful when the model for biblical evangelism is applied. It’s most successful when offered as a service.</li>
        </ul>
        </div>
        </div>
     
        <h1 class="text-center pt-10 text-white ext-3xl tracking-wide md:text-4xl vivo">Why Vivo Vibe?</h1>
        <div class="flex justify-center">
        <div class="w-4/5 p-5 pb-10">
        <ul class="text-white pb-10">
        <li>The founder’s primary motivation for investing heavily into Vivo Vibe is due to seeing pain and heartache in the world and feels the application of this technology can solve a number of societal challenges, he’ll invest in YOU.</li>
        <li>Vivo Vibe marries industry with values and trains individuals to apply industry to their tribe to effect positive outcomes.</li>
        <li>Vivo Vibe is the culmination and combination of the knowledge and experience of someone who has 20 years of industry leading experience in software and has also experienced dramatic success in network marketing over the last 8 years.</li>
        <li> Rather than signing up and buying a product and not knowing what to do, Vivo Vibe employs the most successful business network marketing growth strategies in a simple training and software suite. It is a team, a plan, and an encouragement.</li>
        <li>Vivo Vibe is focused on connecting you to people and ideas on how to optimize your lifestyle, including health, wealth, and relationships. It’s not just a fancy business tool. It’s a lifestyle tool.</li>
        <li>Vivo Vibe trains you with the skills to grow and succeed in life in general, not just network marketing.</li>
        <li>It empowers you with leadership knowledge, health knowledge, relationship knowledge, and puts you into contact with others that do as well.</li>
        <li>As the founder, I intend on taking as many of you as possible around the world with me, I’m incentivized to help you learn, grow, and succeed.</li>
        <li>Vivo Vibe has chosen Young Living essential oils because it is 25 years young and is the established best in industry for natural wellness.</li>
        <li>Vivo Vibe is you! You are what make it great! With an ever expanding userbase of people who work independently, but lock arms and grow together, you’re part of something truly generational.</li>
        </ul>
        </div>
        </div>

            </div>
      </section>
  );
};

Home.propTypes = {
  className: PropTypes.string,
};

Home.defaultProps = {
  className: '',
};

export default Home;
